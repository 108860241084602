<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card class="mt-1">
    <v-toolbar dark color="primary">
      <div class="text-h6">Clock-Check</div>
      <v-divider class="mx-2" vertical />
      <v-autocomplete
        class="mx-1"
        label="Filter by Employees"
        :items="workers"
        v-model="bodyreq.employeeUuid"
        item-text="fullname"
        item-value="uuid"
        style="max-width: 300px"
        prepend-inner-icon="mdi-account-search"
        clearable
        @change="filterByDay"
        @click:clear="resetFilter('employees')"
        hide-details
        outlined
        dense
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.fullname"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.rol"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-html="data.item.phone"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-layout class="ma-4">
        <v-row class="mt-3" no-gutters>
          <v-col class="mt-3">
            <ma-date-picker
              :datepickerProps="{
                'no-title': true,
                max: maxDate,
              }"
              v-model="dateFrom"
              label="From"
              past
            />
          </v-col>
          <v-col class="ml-2 mt-3">
            <ma-date-picker
              :datepickerProps="{
                'no-title': true,
                max: maxDate,
              }"
              v-model="dateTo"
              label="To"
              past
            />
          </v-col>
        </v-row>
      </v-layout>
      <template v-if="validClean">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="ml-2"
              elevation="0"
              small
              rounded
              depressed
              fab
              v-on="on"
              v-bind="attrs"
              @click="resetFilter('custom-select')"
            >
              <v-icon>mdi-backup-restore</v-icon>
            </v-btn>
          </template>
          <span>Clear Dates</span>
        </v-tooltip>
      </template>
    </v-toolbar>
    {{/* Interval indicator */}}
    <v-layout class="pa-4" row>
      <v-layout column>
        <div class="mr-2 text-capitalize">
          {{ filterRanges.replace("-", " ") }}
        </div>

        <v-layout column class="text-body-1 text--secondary">
          <div>
            From:
            <span class="text-caption">{{
              prettyDateShow(bodyreq.dates[0])
            }}</span>
          </div>
          <div>
            To:
            <span class="text-caption">{{
              prettyDateShowTo(bodyreq.dates[1])
            }}</span>
          </div>
        </v-layout>
      </v-layout>
      <template v-if="bodyreq.employeeUuid != null">
        <v-layout class="mt-3" row>
          <v-flex xs2>
            <div>Total:</div>
            <span class="text-caption">
              {{ prettyMinutes(canthours) }}
            </span>
          </v-flex>
          <v-flex xs4>
            <div>Current Salary (usd/h):</div>
            <span class="text-caption">
              {{ payXhours | currency }}
            </span>
          </v-flex>
          <v-flex xs4>
            <div>Total Pay:</div>
            <span class="text-caption">
              {{ totalpay | currency }}
            </span>
          </v-flex>
        </v-layout>
      </template>
    </v-layout>
    <div class="d-flex justify-end my-2">
      <add-clockin @add-success="filterByDay" />
    </div>
    <v-divider />
    <v-data-table
      show-expand
      single-expand
      item-key="possition"
      :loading="loading"
      :expanded.sync="expanded"
      :headers="headers"
      :items="_clockCheck"
      :footer-props="footerProps"
      :options.sync="options"
      class="elevation-1"
    >
      <template v-slot:[`item.CLOKING`]="{ item }">
        <span class="green--text">
          {{
            item.CLOKING != undefined
              ? prettyMinutes(item.CLOKING, item, false)
              : "00h:00m"
          }}</span
        >
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ prettyDay(item.created) }}
      </template>
      <template v-slot:[`item.firstin`]="{ item }">
        <span class="green--text">
          {{
            item.logs != undefined && item.logs != null
              ? _firstin(item.logs)
              : "00h:00m"
          }}</span
        >
      </template>
      <template v-slot:[`item.lastout`]="{ item }">
        <span class="red--text">
          {{
            item.logs != undefined && item.logs != null
              ? _lastout(item.logs)
              : "00h:00m"
          }}</span
        >
      </template>
      <template v-slot:[`item.TotalToPaid`]="{ item }">
        <template v-if="item.TotalToPaid != undefined">
          {{ item.TotalToPaid | currency }}
        </template>
        <template v-else> - </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <btn-add-hours
          :employee="{ fullname: item.name, uuid: item.uuid }"
          :day="item.created"
        />
      </template>
      <template v-slot:[`item.payXhour`]="{ item }">
        <template v-if="item.payXhour != undefined">
          {{ item.payXhour | currency }}
        </template>
        <template v-else> - </template>
      </template>
      <!--  <template v-slot:[`item.usdxh`]="{ item }">
        {{ item.TotalToPaid | currency }}
      </template> -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <template>
            <div class="d-flex ma-2 justify-end">
              <template>
                <div class="text-center">
                  <v-dialog persistent v-model="addClockdialog" width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="success">
                        <v-icon>mdi-clock</v-icon> Add Time</v-btn
                      >
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 white--text green lighten-2">
                        Add Time To: {{ addclockname }}
                        <div>Day: {{ prettyDay(item.created) }}</div>
                      </v-card-title>

                      <v-card-text>
                        <v-layout row class="mt-2">
                          <v-flex xs6 class="pr-1">
                            <v-autocomplete
                              :items="items"
                              item-text="label"
                              item-value="value"
                              v-model="bodyaddclock.status"
                              outlined
                              dense
                              label="Status"
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                        <v-layout row class="mt-2">
                          <v-flex xs12 class="pr-1">
                            <v-text-field
                              label="Details"
                              outlined
                              v-model="bodyaddclock.details"
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row class="mt-2">
                          <v-flex xs6>
                            <v-menu
                              ref="menustart"
                              v-model="startdatemenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="bodyaddclock.dateRange.date1"
                                  label="Start Time"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="startdatemenu"
                                ampm-in-title
                                v-model="bodyaddclock.dateRange.date1"
                                full-width
                              ></v-time-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs6 class="pr-1">
                            <v-menu
                              ref="menuend"
                              v-model="enddatemenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="bodyaddclock.dateRange.date2"
                                  label="End Time"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                ampm-in-title
                                v-if="enddatemenu"
                                v-model="bodyaddclock.dateRange.date2"
                                full-width
                              ></v-time-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" text @click="cancelAddClock">
                          Cancel
                        </v-btn>
                        <v-btn color="primary" text @click="addClock">
                          Confirm
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </template>
            </div>
          </template>
          <template>
            <div class="d-flex mx-2 justify-center">
              <div class="col-sm-6">
                <template
                  v-if="item.logs != undefined && item.logs.length != 0"
                >
                  <v-sheet
                    color="green accent-1"
                    elevation="4"
                    height="100%"
                    width="100%"
                    class="pa-1"
                  >
                    <strong>Logs:</strong>
                    <div class="d-flex justify-center">
                      <span class="headline font-weight-bold green--text">
                        {{ "Clock-In: " + _firstin(LogsExpand) }}</span
                      >
                      <template>
                        <v-dialog
                          ref="dialog"
                          v-model="editclockin"
                          :return-value.sync="clockinhour"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on: dialog }">
                            <v-tooltip transition="fade-transition" top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  :color="btnProps.color || 'primary'"
                                  icon
                                  depressed
                                  v-on="{ ...dialog, ...tooltip }"
                                  v-bind="btnProps"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>

                              <span>Edit Clock-In</span>
                            </v-tooltip>
                          </template>
                          <v-time-picker
                            ampm-in-title
                            v-if="editclockin"
                            v-model="clockinhour"
                            scrollable
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="canceledit">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              :disabled="
                                clockinhour == '' || clockinhour == null
                              "
                              color="primary"
                              @click="editClock('in')"
                            >
                              Edit
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </template>
                    </div>
                    <template>
                      <v-timeline>
                        <v-timeline-item
                          v-for="(h, i) in LogsExpand"
                          :key="i"
                          small
                          :color="h.status == 'CLOKING' ? 'success' : 'error'"
                        >
                          <template v-slot:opposite>
                            <span
                              class="headline font-weight-bold"
                              v-text="prettyDateH(h.startDate)"
                            ></span>
                          </template>
                          <v-card
                            :color="h.status == 'CLOKING' ? 'success' : 'error'"
                            dark
                          >
                            <v-card-title class="text-h6">
                              <template v-if="i % 2 == 0">
                                {{
                                  h.status == "CLOKING"
                                    ? "CLOCK-IN"
                                    : "CLOCK-OUT"
                                }}
                                <v-spacer></v-spacer>

                                <template>
                                  <v-tooltip transition="fade-transition" top>
                                    <template
                                      v-slot:activator="{ on: tooltip }"
                                    >
                                      <v-btn
                                        color="primary"
                                        icon
                                        @click="_toEdit(h, item)"
                                        depressed
                                        v-on="{ ...tooltip }"
                                        v-bind="btnProps"
                                      >
                                        <v-icon>mdi-pencil</v-icon>
                                      </v-btn>
                                    </template>

                                    <span>Edit Clock-In</span>
                                  </v-tooltip>
                                </template>
                                <template>
                                  <v-tooltip transition="fade-transition" top>
                                    <template
                                      v-slot:activator="{ on: tooltip }"
                                    >
                                      <v-btn
                                        :color="
                                          h.status == 'CLOKING'
                                            ? 'error'
                                            : 'red lighten-4'
                                        "
                                        icon
                                        depressed
                                        @click="_toDelete(h)"
                                        v-on="{ ...tooltip }"
                                        v-bind="btnProps"
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>

                                    <span>Delete Clock-In</span>
                                  </v-tooltip>
                                </template>
                              </template>
                              <template v-else>
                                <template>
                                  <v-tooltip transition="fade-transition" top>
                                    <template
                                      v-slot:activator="{ on: tooltip }"
                                    >
                                      <v-btn
                                        :color="
                                          h.status == 'CLOKING'
                                            ? 'error'
                                            : 'red lighten-4'
                                        "
                                        icon
                                        depressed
                                        @click="_toDelete(h)"
                                        v-on="{ ...tooltip }"
                                        v-bind="btnProps"
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>

                                    <span>Delete Clock-In</span>
                                  </v-tooltip>
                                </template>
                                <template>
                                  <v-tooltip transition="fade-transition" top>
                                    <template
                                      v-slot:activator="{ on: tooltip }"
                                    >
                                      <v-btn
                                        color="primary"
                                        icon
                                        @click="_toEdit(h, item)"
                                        depressed
                                        v-on="{ ...tooltip }"
                                        v-bind="btnProps"
                                      >
                                        <v-icon>mdi-pencil</v-icon>
                                      </v-btn>
                                    </template>

                                    <span>Edit Clock-In</span>
                                  </v-tooltip>
                                </template>

                                <v-spacer></v-spacer>
                                {{
                                  h.status == "CLOKING"
                                    ? "CLOCK-IN"
                                    : "CLOCK-OUT"
                                }}
                              </template>
                            </v-card-title>
                            <v-card-text class="white text--primary mt-2">
                              <template v-if="h.status == 'HOLD'">
                                <p>{{ h.details }}</p>
                              </template>
                              <p>Time: {{ prettyMinutes(h.minutes) }}</p>
                              <template v-if="h.status == 'CLOKING'">
                                <p>
                                  End Date:
                                  <template v-if="h.endDate != null">
                                    {{ prettyDateH(h.endDate) }}
                                  </template>
                                  <template v-else>
                                    {{ $t("active") }}
                                  </template>
                                </p>
                              </template>
                            </v-card-text>
                          </v-card>
                        </v-timeline-item>
                      </v-timeline>
                    </template>
                    <div class="d-flex justify-center">
                      <span class="headline font-weight-bold red--text">
                        {{ "Clock-Out: " + _lastout(LogsExpand) }}</span
                      >
                      <template>
                        <v-dialog
                          ref="dialog"
                          v-model="editclockout"
                          :return-value.sync="clockouthour"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on: dialog }">
                            <v-tooltip transition="fade-transition" top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  :color="btnProps.color || 'primary'"
                                  icon
                                  depressed
                                  v-on="{ ...dialog, ...tooltip }"
                                  v-bind="btnProps"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>

                              <span>Edit Clock-Out</span>
                            </v-tooltip>
                          </template>
                          <v-time-picker
                            ampm-in-title
                            scrollable
                            v-if="editclockout"
                            v-model="clockouthour"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="canceledit">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              :disabled="
                                clockouthour == '' || clockouthour == null
                              "
                              color="primary"
                              @click="editClock('out')"
                            >
                              Edit
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </template>
                    </div>
                  </v-sheet>
                  <template v-if="editLog.editLogDialog">
                    <v-dialog
                      persistent
                      v-model="editLog.editLogDialog"
                      width="600"
                    >
                      <v-card>
                        <v-card-title
                          class="text-h5 white--text green lighten-2"
                        >
                          Edit Clock-in:
                          <div>
                            {{ prettyDay(editLog.datecreated) }}
                          </div>
                        </v-card-title>

                        <v-card-text>
                          <v-layout row class="mt-2">
                            <v-flex xs6>
                              <v-menu
                                v-model="editLog.startmenulog"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editLog.loghourin"
                                    label="Start Date"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  ampm-in-title
                                  v-if="editLog.startmenulog"
                                  v-model="editLog.loghourin"
                                  full-width
                                ></v-time-picker>
                              </v-menu>
                            </v-flex>
                            <v-flex xs6 class="pr-1">
                              <v-menu
                                v-model="editLog.endmenulog"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editLog.loghourout"
                                    label="End Date"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  ampm-in-title
                                  v-if="editLog.endmenulog"
                                  v-model="editLog.loghourout"
                                  full-width
                                ></v-time-picker>
                              </v-menu>
                            </v-flex>
                          </v-layout>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="canceledit">
                            Cancel
                          </v-btn>
                          <v-btn
                            color="primary"
                            text
                            :disabled="_disabledEditlog"
                            @click="editClock('all')"
                          >
                            Confirm
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>

                  <template v-if="deleteLog.dialogdeletelog">
                    <v-dialog
                      persistent
                      v-model="deleteLog.dialogdeletelog"
                      width="300"
                    >
                      <v-card>
                        <v-card-title class="text-h5 white--text red lighten-2">
                          Delete Clock-in
                        </v-card-title>

                        <v-card-text>
                          <h2>Are you sure ?</h2>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="cancelDelete()">
                            Cancel
                          </v-btn>
                          <v-btn
                            color="primary"
                            text
                            @click="confirmDeleteLog()"
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </td></template
      >
    </v-data-table>
  </v-card>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import moment from "moment";
import * as mont from "moment-timezone";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import BtnAddHours from "@/views/Employees/buttons/BtnAddHours.vue";
import AddClockin from "@/views/Configurations/components/AddClockin.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
export default {
  props: {
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { MaDatePicker, BtnAddHours, AddClockin },
  data: () => ({
    addClockdialog: false,

    editLog: {
      datecreated: null,
      clockingUuid: null,
      day: null,
      loghourin: null,
      loghourout: null,
      editLogDialog: false,
      startmenulog: false,
      endmenulog: false,
    },
    deleteLog: {
      dialogdeletelog: false,
      uuid: null,
    },
    clockinhour: null,
    clockouthour: null,
    editclockout: false,
    editclockin: false,
    loadingclock: false,
    expanded: [],
    imagesLogsExpanded: [],
    footerProps: { "items-per-page-options": [15, 25, 50, 100, 500] },
    options: {},
    filterRanges: "today",
    filterEmployees: "",
    dateFrom: "",
    maxDate: new Date().toISOString(),
    dateTo: "",
    LogsExpand: [],

    headers: [
      {
        text: "Employee",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Day",
        value: "created",
        sortable: false,
      },
      {
        text: "Clock-in",
        value: "firstin",
        sortable: false,
      },
      {
        text: "Clock-out",
        value: "lastout",
        sortable: false,
      },
      {
        text: "Active(Hours)",
        value: "CLOKING",
        sortable: false,
      },

      /*  {
        text: "Total(Hours)",
        value: "minutes",
      }, */
      {
        text: "USD/H",
        value: "payXhour",
        align: "end",
        sortable: false,
      },
      {
        text: "Total Pay",
        value: "TotalToPaid",
        align: "end",
        sortable: false,
      },

      { text: "", value: "actions" },
      { text: "", value: "data-table-expand" },
    ],

    items: [
      { label: "CLOCK-IN", value: "CLOKING" },
      { label: "CLOCK-OUT", value: "CLOCKOUT" },
    ],

    intervals: [
      { label: "Today", value: "today" },
      { label: "Yesterday", value: "yesterday" },
      { label: "This week", value: "this-week" },
      { label: "Last week", value: "last-week" },
      { label: "This month", value: "this-month" },
      { label: "Last month", value: "last-month" },
      { label: "This year", value: "this-year" },
      { label: "Last year", value: "last-year" },
    ],
    addclockname: "",
    startdatemenu: false,
    enddatemenu: false,

    bodyaddclock: {
      status: "CLOKING",
      details: "",
      day: "",
      dateRange: {
        date1: "",
        date2: "",
      },
      employeeUuid: "",
    },
    bodyreq: {
      employeeUuid: null,

      dates: ["", ""],
      range: {
        limit: 15,
        offset: 0,
      },
    },
  }),
  watch: {
    expanded(val) {
      this.LogsExpand = [];
      if (val.length != 0) {
        const vl = val[0];

        this.addclockname = vl.name;
        this.bodyaddclock.employeeUuid = vl.uuid;
        this.bodyaddclock.day = vl.created;
        if (val[0].logs != undefined && val[0].length != 0) {
          const logtem = val[0].logs;

          this.LogsExpand = logtem;
        } else {
          this.LogsExpand = [];
        }
      } else {
        this.LogsExpand = [];
        this.addclockname = "";
        this.bodyaddclock = {
          status: "CLOKING",
          details: "",
          day: "",
          dateRange: {
            date1: "",
            date2: "",
          },
          employeeUuid: "",
        };
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRanges = "custom-select";
        this.filterByDay();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRanges = "custom-select";
        this.filterByDay();
      }
    },
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["clockdays", "loading"]),
    ...mapState("crmEmployeeModule", ["workers"]),

    _disabledEditlog() {
      if (this.editLog.loghourin == null && this.editLog.loghourout == null) {
        return true;
      }
      return false;
    },
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
    payXhours() {
      if (this.bodyreq.employeeUuid == null) {
        return 0;
      }
      const payx = this.workers.filter(
        (w) => w.uuid == this.bodyreq.employeeUuid
      )[0];

      return payx.payXhour;
    },
    totalpay() {
      if (this.clockdays.length == 0) {
        return 0;
      }
      let canth = 0;
      this.clockdays.forEach((element) => {
        const mc = element.TotalToPaid != undefined ? element.TotalToPaid : 0;

        canth = canth + mc;
      });

      return canth;
    },

    canthours() {
      if (this.clockdays.length == 0) {
        return 0;
      }
      let canth = 0;
      this.clockdays.forEach((element) => {
        const mc = element.CLOKING != undefined ? element.CLOKING : 0;
        const mh = element.HOLD != undefined ? element.HOLD : 0;
        canth = canth + (mc + mh);
      });

      return canth;
    },

    _clockCheck() {
      if (this.clockdays.length != 0) {
        let clock = this.clockdays;

        let clocktem = [];
        clocktem = clock.map((c, i) => {
          const temp = c;
          temp.possition = i;
          return temp;
        });

        return clocktem;
      }
      return [];
    },

    /*INTERVALS*/
    intervalToday() {
      const from = moment().startOf("day").utc().toISOString();
      const to = moment().utc().toISOString();

      return {
        date1: from,
        date2: to,
      };
    },
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actFilterDay", "actAddClockIn"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    _getName(uuid) {
      const flt = this.workers.filter((w) => w.uuid == uuid);
      if (flt.length != 0) {
        return flt[0];
      }
      return null;
    },
    _firstin(logs) {
      if (logs == undefined || logs.length == 0) {
        return "00h:00m";
      }
      const itm = logs[0];
      return this.prettyDateH(itm.startDate);
    },
    _lastout(logs) {
      if (logs == undefined || logs.length == 0) {
        return "00h:00m";
      }
      const itm = logs[logs.length - 1];
      let last = "Active";
      if (itm.endDate != null && itm.endDate != undefined) {
        last = this.prettyDateH(itm.endDate);
      }
      return last;
    },

    prettyMinutes(minutes, item, hold) {
      const mins = Number(minutes);

      if (mins < 1) {
        return "00h:00m";
      }
      let hour = Math.trunc(mins / 60);
      hour = hour < 10 ? "0" + hour : hour;
      var minute = Math.trunc(mins % 60);

      minute = minute < 10 ? "0" + minute : minute;
      return hour + "h:" + minute + "m";
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      const temp = mont(date).tz("America/New_York");
      const dat = moment(temp._d);

      return dat.format("dddd D MMMM YYYY, h:mm:ss a");
    },
    prettyDateShow(date) {
      if (date === null || date === "") return "";
      /*   if (this.filterRanges == "custom-select") {
        return moment(date)
          .utc()
          .tz("America/New_York")
          .local()
          .format("dddd D MMMM YYYY, h:mm:ss a");
      } */
      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    prettyDateShowTo(date) {
      if (date === null || date === "") return "";
      /*  if (this.filterRanges == "custom-select") {
        return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
      } */

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    prettyDateH(date) {
      if (date === null || date === "") return "";
      return moment(date).subtract(4, "hour").format("h:mm:ss a");
    },
    prettyDay(date) {
      if (date === null || date === "") return "";

      const fecha = moment(date)
        .utc()
        .startOf("day")
        .format("dddd D MMMM YYYY, h:mm:ss a");

      const day =
        fecha.split(" ")[0] +
        " " +
        fecha.split(" ")[1] +
        ", " +
        fecha.split(" ")[2];

      return day;
    },

    prettyDetails(details) {
      if (details.length <= 20) {
        return details;
      } else {
        return details.substring(0, 20) + "...";
      }
    },

    async setIntervar(interval) {
      this.filterRanges = interval;
      await this.filterByDay();
    },

    async filterByDay() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      let body = {
        dateDange: this.bodyreq.dates,
        employeeUuid: this.bodyreq.employeeUuid,
        range: this.bodyreq.range,
      };
      body.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };

      switch (this.filterRanges) {
        case "today":
          body.dateDange = [this.intervalToday.date1, this.intervalToday.date2];
          break;

        case "custom-select":
          body.dateDange = [
            moment(this.dateFrom).startOf("day").utc().toISOString(),
            moment(this.dateTo).endOf("day").utc().toISOString(),
          ];
          break;
      }
      this.bodyreq.dates = body.dateDange;
      this.expanded = [];

      if (body.employeeUuid != null && body.employeeUuid != undefined) {
        const nam = this._getName(body.employeeUuid);
        body = { ...body, employee: nam };
      }

      await this.actFilterDay(body);
    },

    resetFilter(value) {
      switch (value) {
        case "custom-select":
          this.filterRanges = "today";
          this.dateFrom = "";
          this.dateTo = "";
          this.filterByDay();
          break;
        case "employees":
          this.filterEmployees = "";
          this.bodyreq.employeeUuid = null;
          this.filterByDay();
          break;
        default:
          this.filterEmployees = "";

          this.filterRanges = "today";
          this.bodyreq = {
            employeeUuid: null,
            dates: ["", ""],
          };
          this.filterByDay();
          break;
      }
    },
    canceledit() {
      this.clockinhour = null;
      this.clockouthour = null;
      this.editclockout = false;
      this.editclockin = false;
      this.editLog = {
        datecreated: null,
        clockingUuid: null,
        day: null,
        loghourin: null,
        loghourout: null,
        editLogDialog: false,
        startmenulog: false,
        endmenulog: false,
      };
    },
    addClock() {
      const body = this.bodyaddclock;
      const dayf = body.day.split("T")[0];
      const hd1 = body.dateRange.date1.split(":")[0];
      const md1 = body.dateRange.date1.split(":")[1];
      const hd2 = body.dateRange.date2.split(":")[0];
      const md2 = body.dateRange.date2.split(":")[1];

      const startD = moment(dayf).hour(hd1).minute(md1).toISOString();
      const endD = moment(dayf).hour(hd2).minute(md2).toISOString();

      body.dateRange = { date1: startD, date2: endD };

      delete body.day;

      this.actAddClockIn(body).then(() => {
        this.expanded = [];
        this.addClockdialog = false;
        this.filterByDay();
      });
    },
    cancelAddClock() {
      this.addClockdialog = false;
    },
    _toEdit(log, item) {
      this.editLog.datecreated = item.created;
      this.editLog.clockingUuid = log.uuid;
      this.editLog.day = log.createdAt.split("T")[0];
      this.editLog.editLogDialog = true;

      const timeintemp = moment(log.startDate)
        .subtract(4, "hour")
        .format("HH:mm");
      const timeouttemp = moment(log.endDate)
        .subtract(4, "hour")
        .format("HH:mm");
      this.editLog.loghourin = timeintemp;
      this.editLog.loghourout = timeouttemp;
      this.editLog.loghourin;
    },

    editClock(type) {
      if (type == "in") {
        const clockingUuid = this.LogsExpand[0].uuid;
        const h = Number(this.clockinhour.split(":")[0]);
        const m = Number(this.clockinhour.split(":")[1]);

        const day = moment(this.LogsExpand[0].startDate);
        day.startOf("day").utc();
        day.add(h, "hour");
        day.add(m, "minute");

        this.loadingclock = true;
        getAPI
          .put("/clocking/update", {
            clockingUuid: clockingUuid,
            startDate: day.toISOString(),
          })
          .then(() => {
            this.loadingclock = false;
            this.expanded = [];
            notifySuccess("clock-in has been changed");
            this.canceledit();
            this.filterByDay();
          })
          .catch((error) => {
            this.loadingclock = false;
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else if (type == "out") {
        const clockingUuid = this.LogsExpand[this.LogsExpand.length - 1].uuid;
        const h = Number(this.clockouthour.split(":")[0]);
        const m = Number(this.clockouthour.split(":")[1]);
        const day = moment(this.LogsExpand[this.LogsExpand.length - 1].endDate);
        day.hour(h).minute(m).second(0);
        day.utc();
        this.loadingclock = true;
        getAPI
          .put("/clocking/update", {
            clockingUuid: clockingUuid,
            endDate: day.toISOString(),
          })
          .then(() => {
            this.loadingclock = false;
            this.expanded = [];
            notifySuccess("clock-out has been changed");
            this.canceledit();
            this.filterByDay();
          })
          .catch((error) => {
            this.loadingclock = false;
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        const body = { clockingUuid: "", startDate: "", endDate: "" };
        body.clockingUuid = this.editLog.clockingUuid;
        if (this.editLog.loghourin != null) {
          const hi = Number(this.editLog.loghourin.split(":")[0]);
          const mi = Number(this.editLog.loghourin.split(":")[1]);
          const dayi = moment(this.editLog.day);
          dayi.hour(hi).minute(mi).second(0);
          dayi.utc();
          body.startDate = dayi.toISOString();
        }

        if (this.editLog.loghourout != null) {
          const ho = Number(this.editLog.loghourout.split(":")[0]);
          const mo = Number(this.editLog.loghourout.split(":")[1]);

          const dayo = moment(this.editLog.day);

          dayo.hour(ho).minute(mo).second(0);
          dayo.utc();
          body.endDate = dayo.toISOString();
        }

        if (body.endDate == "") {
          delete body.endDate;
        }
        if (body.startDate == "") {
          delete body.startDate;
        }

        this.loadingclock = true;
        getAPI
          .put("/clocking/update", body)
          .then(() => {
            this.loadingclock = false;
            this.expanded = [];
            notifySuccess("clock-out has been changed");
            this.canceledit();
            this.filterByDay();
          })
          .catch((error) => {
            this.loadingclock = false;
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
      this.loadingclock = false;
      this.canceledit();
    },
    _toDelete(value) {
      this.deleteLog.uuid = value.uuid;
      this.deleteLog.dialogdeletelog = true;
    },
    cancelDelete() {
      this.deleteLog = { uuid: null, dialogdeletelog: false };
    },

    confirmDeleteLog() {
      const uuid = this.deleteLog.uuid;

      getAPI
        .delete("/clocking/delete/" + uuid)
        .then(() => {
          notifyInfo("Clock-in deleted!");
          this.cancelDelete();
          this.filterByDay();
        })
        .catch((error) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);

          this.cancelDelete();
        });
    },
  },
  async mounted() {
    this.actGetEmployees();
    await this.setIntervar(this.filterRanges);
  },
};
</script>
<style lang="scss" scope></style>
